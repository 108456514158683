@import-normalize; /* bring in normalize.css styles */
/* We rely on source order for mobile-first approach
 * in this case:
 * 1. header
 * 2. article
 * 3. aside 1
 * 4. aside 2
 * 5. footer
 */

 @font-face {
    font-family: 'Montserrat Regular';
    src: local('Montserrat Regular'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  }

 

body {
    font-family: Montserrat Regular !important
}